<template>
  <div class="box">
    <div class="box_1">
      <div class="top">
        <div class="img">
          <img
            :src="item.thumbnail"
            alt=""
            @click="openDetail()"
            @error="replaceImg"
          />
          <span>{{ index }}</span>
        </div>
        <div class="txt">
          <h3 @click="openDetail()">
            {{ item.title }}
          </h3>
          <p @click="openDetail()">상품명 : {{ item.main_goods_name }}</p>
          <!-- <p @click="openDetail()">상품명 : {{ item.goods_name }}</p> -->
        </div>
      </div>
      <ul class="bottom">
        <template v-if="parseInt(item.option_category1) !== 3">
          <li>
            <router-link
              to=""
              @click.native="
                likeCheck({
                  wish_yn: item.wish_yn_reviwer,
                  reviewer_seq: item.member_seq,
                  review_seq: null,
                  main_seq: item.main_menu_seq,
                  type: 'reviewer',
                })
              "
            >
              <img
                v-if="item.wish_yn_reviwer == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
        </template>
        <template v-else>
          <li>
            <router-link
              to=""
              @click.native="
                likeShop({
                  wish_yn: item.mini_shop_wish,
                  reviewer_seq: null,
                  review_seq: item.provider_seq,
                  main_seq: item.main_menu_seq,
                  type: 'shop',
                })
              "
            >
              <img
                v-if="item.mini_shop_wish == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
        </template>

        <li v-if="parseInt(item.option_category1) !== 3">
          리뷰어 신뢰도 {{ rammFloat(item.re_trust_score) }}
        </li>
        <li v-if="parseInt(item.option_category1) === 3">
          리뷰 신뢰도 {{ rammFloat(item.trust_score) }}
        </li>
        <li>제품 평점 {{ item.main_score }}</li>
        <li>
          <router-link
            to=""
            :id="`arrow_${item.seq}`"
            @click.native="openDetail()"
            class="detail_arrow"
            >∨</router-link
          >
        </li>
      </ul>
      <router-link
        to=""
        @click.native="goDetailList"
        class="move_ico"
        v-if="!goods"
        ><img src="@/assets/images/sub/list_move_ico.jpg" alt=""
      /></router-link>
    </div>
    <div
      class="box_2 detail"
      style="display: none"
      :id="`item_detail_${item.seq}`"
    >
      <!--상품-일반/특정-->
      <div class="top_2" v-if="parseInt(item.option_category1) === 1">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isReviewer) &&
              parseInt(item.sale_cnt) <= 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="modifyReview('normal')"
              ><span>수정</span></router-link
            ><router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isReviewer) &&
              parseInt(item.sale_cnt) > 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <span v-if="item.sale_stop_yn === 'Y'">판매<br />중지</span>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          >
          <!-- <br />리뷰어의 제품 평점&nbsp;&nbsp;{{ item.main_score }} -->
        </div>
      </div>
      <!--상품-전문가-->
      <div class="top_2" v-if="parseInt(item.option_category1) === 2">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isReviewer) &&
              parseInt(item.sale_cnt) <= 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="modifyReview('pro')"
              ><span>수정</span></router-link
            ><router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isReviewer) &&
              parseInt(item.sale_cnt) > 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <span v-if="item.sale_stop_yn === 'Y'">판매<br />중지</span>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          >
        </div>
      </div>
      <!-- 판매자-->
      <div class="top_1" v-if="parseInt(item.option_category1) === 3">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isSeller) &&
              parseInt(item.sale_cnt) <= 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="modifyReview('seller')"
              ><span>수정</span></router-link
            ><router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isSeller) &&
              parseInt(item.sale_cnt) > 0 &&
              item.sale_stop_yn === 'N'
            "
          >
            <router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            >
          </template>
          <span v-if="item.sale_stop_yn === 'Y'">판매<br />중지</span>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          ><br />리뷰어의 제품 평점&nbsp;&nbsp;{{ item.main_score }}
        </div>
      </div>
      <h3>
        {{ item.title }}
      </h3>
      <!--상품-전문가-->
      <!-- <div class="grade" v-if="parseInt(item.option_category1) == 2">
        <div class="left">리뷰어의 제품 평점</div>
        <div class="right">
          <div>
            <span class="txt">A({{ item.main_goods_name }})</span
            ><span class="num">{{ item.main_score }}</span>
          </div>
          <template v-for="(compare, index) in compareGoods(item)">
            <div :key="index">
              <span class="txt"
                >{{ parseInt(index) < 1 ? "B" : "C" }} ({{
                  compare.goods_name
                }})</span
              >
              <span class="num">{{ compare.score }}</span>
            </div>
          </template>
        </div>
      </div> -->

      <div class="middle" v-html="item.content">
        {{ item.content }}
      </div>
      <div class="middle_end">
        <div class="left">{{ item.nickname }}</div>
        <div class="right">{{ day(item.reg_dt) }}</div>
      </div>

      <hr class="content_line" />

      <div
        class="btn_1"
        v-if="
          parseInt(item.option_category1) !== 2 &&
          item.button_yn === 'Y' &&
          item.sale_stop_yn === 'N'
        "
      >
        <a href="javascript:;" class="no_sale" v-if="item.goods_status === null"
          >판매종료</a
        >
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'runout'"
          >품절</a
        >
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'unsold'"
          >판매중지</a
        >
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'purchasing'"
          >재고확보중</a
        >
        <router-link
          to=""
          v-if="item.goods_status === 'normal'"
          @click.native="goOrder(category, item.goods_seq)"
          >구매하기</router-link
        >
      </div>
      <div
        class="btn_1"
        v-if="
          parseInt(item.option_category1) == 2 &&
          item.button_yn === 'Y' &&
          item.sale_stop_yn === 'N'
        "
      >
        <a href="javascript:;" class="no_sale" v-if="item.goods_status === null"
          >판매종료A</a
        >
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'runout'"
          >품절A
        </a>
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'unsold'"
          >판매중지A
        </a>
        <a
          href="javascript:;"
          class="no_sale"
          v-if="item.goods_status === 'purchasing'"
          >재고확보중A
        </a>
        <router-link
          to=""
          @click.native="goOrder(category, item.goods_seq)"
          v-if="item.goods_status === 'normal'"
          >구매하기A
        </router-link>
        <template v-for="(compare, index) in compareGoods(item)">
          <a
            href="javascript:;"
            v-if="chkGoodsStatus(compare.seq) === null"
            :key="index"
            class="no_sale"
            >판매종료{{ parseInt(index) < 1 ? "B" : "C" }}
          </a>
          <a
            href="javascript:;"
            v-if="chkGoodsStatus(compare.seq) === 'runout'"
            :key="index"
            class="no_sale"
            >품절{{ parseInt(index) < 1 ? "B" : "C" }}
          </a>
          <a
            href="javascript:;"
            v-if="chkGoodsStatus(compare.seq) === 'unsold'"
            :key="index"
            class="no_sale"
            >판매중지{{ parseInt(index) < 1 ? "B" : "C" }}
          </a>
          <a
            href="javascript:;"
            v-if="chkGoodsStatus(compare.seq) === 'purchasing'"
            :key="index"
            class="no_sale"
            >재고확보중{{ parseInt(index) < 1 ? "B" : "C" }}
          </a>
          <router-link
            to=""
            v-if="chkGoodsStatus(compare.seq) === 'normal'"
            :key="index"
            @click.native="goCompareItem(index)"
            >구매하기{{ parseInt(index) < 1 ? "B" : "C" }}
          </router-link>
        </template>
      </div>
      <div class="btn_end" v-if="item.sale_stop_yn === 'Y'">
        리뷰어의 요청으로 해당 리뷰를 통한 [구매하기]가 중지되었습니다. 다른
        리뷰를 참조해 주세요!
      </div>
      <div class="info_bt" @click="openInfo()">
        * 제품 구매자는 평점을 매겨주세요! 포인트를 드립니다.<router-link to=""
          ><span :id="`arrow_info_${item.seq}`" class="arrow_info"
            >∨</span
          ></router-link
        >
      </div>
      <div class="info_word" :id="`arrow_info_word_${item.seq}`">
        <p>
          '리뷰 만족도', '제품 평점' 매기기, '댓글 쓰기' 는 이 리뷰를 통해 제품
          및 서비스를 구매하신 분들만 할 수 있습니다. 여러분의 활동에 의해 이
          리뷰와 리뷰어의 신뢰도가 결정됩니다.
        </p>
        <p>
          리뷰어의 리뷰 중 과장이나 축소, 왜곡 등등은 모두 부정직한 리뷰입니다.
          리뷰의 본질을 망각한 글은 누구나 '신고'할 수 있으며 일정 수 이상에
          의해 신고된 리뷰는 자동 검색 누락이 됩니다. 악의적인 신고로 피해를
          당한 리뷰어는 당사에 항의할 수 있으며 피해자로 확인될 경우 그 리뷰는
          복원됩니다.
        </p>
      </div>

      <!-- <div class="score_num">
        <span>리뷰 만족도</span>
        <ul v-if="0 < parseInt(reviewScore(item.review_score))">
          <li
            v-for="(count, index) in parseInt(reviewScore(item.review_score))"
            :key="`${index}_on`"
          >
            <router-link to="" @click.native="setReviewScore(count)"
              ><img
                :src="require(`@/assets/images/sub/score_num_${count}_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 -
            parseInt(reviewScore(item.review_score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setReviewScore(count + parseInt(reviewScore(item.review_score)))
              "
              ><img
                :src="
                  require(`@/assets/images/sub/score_num_${
                    count + parseInt(reviewScore(item.review_score))
                  }_off.png`)
                "
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link to="" @click.native="setReviewScore(count)"
              ><img
                :src="require(`@/assets/images/sub/score_num_${count}_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <!-- <div class="score_star" v-if="parseInt(item.option_category1) !== 2">
        <span>제품 평점</span>
        <ul v-if="0 < parseInt(evaluationScore(item.score))">
          <li
            v-for="(count, index) in parseInt(evaluationScore(item.score))"
            :key="`${index}_on`"
          >
            <router-link
              to=""
              @click.native="setGoodsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 - parseInt(evaluationScore(item.score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setGoodsScore(
                  count + parseInt(evaluationScore(item.score)),
                  item.goods_seq
                )
              "
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link
              to=""
              @click.native="setGoodsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <!--상품-전문가-->
      <!-- <div class="score_star" v-if="parseInt(item.option_category1) == 2">
        <span>제품 A 평점</span>
        <ul v-if="parseInt(item.goods_seq) === parseInt(item.main_goods_seq)">
          <li
            v-for="(count, index) in parseInt(evaluationScore(item.score))"
            :key="`${index}_on`"
          >
            <router-link
              to=""
              @click.native="setGoodsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 - parseInt(evaluationScore(item.score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setGoodsScore(
                  count + parseInt(evaluationScore(item.score)),
                  item.goods_seq
                )
              "
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link
              to=""
              @click.native="setGoodsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <!-- <div
        class="score_star"
        v-if="parseInt(item.option_category1) == 2 && compareItem1"
      >
        <span>제품 B 평점</span>
        <ul v-if="parseInt(compareItem1) === parseInt(item.main_goods_seq)">
          <li
            v-for="(count, index) in parseInt(evaluationScore(item.score))"
            :key="`${index}_on`"
          >
            <router-link
              to=""
              @click.native="setGoodsScore(count, compareItem1)"
              ><img
                :src="require(`@/assets/images/sub/score_star_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 - parseInt(evaluationScore(item.score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setGoodsScore(
                  count + parseInt(evaluationScore(item.score)),
                  compareItem1
                )
              "
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link
              to=""
              @click.native="setGoodsScore(count, compareItem1)"
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <!-- <div
        class="score_star"
        v-if="parseInt(item.option_category1) == 2 && compareItem2"
      >
        <span>제품 C 평점</span>
        <ul v-if="parseInt(compareItem2) === parseInt(item.main_goods_seq)">
          <li
            v-for="(count, index) in parseInt(evaluationScore(item.score))"
            :key="`${index}_on`"
          >
            <router-link
              to=""
              @click.native="setGoodsScore(count, compareItem2)"
              ><img
                :src="require(`@/assets/images/sub/score_star_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 - parseInt(evaluationScore(item.score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setGoodsScore(
                  count + parseInt(evaluationScore(item.score)),
                  compareItem2
                )
              "
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link
              to=""
              @click.native="setGoodsScore(count, compareItem2)"
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <ReviewSetScore
        :id="1"
        :item="item"
        :myInfo="myInfo"
        @updateScores="updateScores"
        @needsLogin="needsLogin"
        ref="review_score"
      />
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <div class="btn_2">
        <router-link
          to=""
          class="left black"
          @click.native="
            likeCheck({
              wish_yn: item.wish_yn,
              review_seq: item.seq,
              reviewer_seq: null,
              main_seq: item.main_menu_seq,
              type: 'review',
            })
          "
          >♡ 이 리뷰 찜하기</router-link
        >
        <router-link to="" @click.native="openDeclaration()" class="right white"
          >신고하기</router-link
        >
        <router-link to="" class="right gray" @click.native="openPopSns()"
          >SNS공유하기</router-link
        >
        <ReviewSnsShare
          :item="item"
          :nowPage="pageUrl"
          :ref="`sns_share_${item.seq}`"
          :id="`sns_share_${item.seq}`"
        />
        <ReviewDeclaration
          :item="item"
          :id="`declaration_${item.seq}`"
          style="display: none"
        />
      </div>
      <div class="info_txt">
        상품/서비스에 대한 리뷰어의 리뷰는 회사의 견해가 아닙니다. 리뷰에 대한
        책임은 해당 리뷰를 작성한 리뷰어에게 있습니다. 이용자들은 다양한 리뷰를
        참조해 상품/서비스를 구매/이용하시기 바랍니다.
      </div>
      <div class="reply_btn" @click="openReply()">
        <router-link to=""
          >댓글 {{ item.comment_cnt }}개
          <span :id="`arrow_reply_${item.seq}`" class="arrow_reply"
            >∨</span
          ></router-link
        >
      </div>
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <ReviewReply
        :id="`reply_view_${item.seq}`"
        :item="item"
        :replyListArray="replyListArray"
        :page="parseInt(replyPage)"
        :ref="`reply_view_${item.seq}`"
        @setSort="setSort"
        @getReplyList="getReplyList"
        class="reply_list_box"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewSnsShare from "@/components/review/state/ReviewSnsShare";
import ReviewDeclaration from "@/components/review/state/ReviewDeclaration";
import ReviewReply from "@/components/review/state/ReviewReply";
import ReviewSetScore from "@/components/review/state/ReviewSetScore.vue";
export default {
  data() {
    return {
      reviewTmpScore: 0,
      tmPscore: 0,
      orgReviewScore: this.item.review_score,
      orgScore: this.item.score,
      replyPage: 0,
      sort: "DESC",
      goodsMenu: 1,
      isOpen: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: "",
    },
    nowPage: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    goods: {
      type: Boolean,
      default: false,
    },
    paramsQuery: {
      type: String,
      default: "",
    },
  },
  methods: {
    replaceImg(e) {
      if (this.item.goods_img) {
        e.target.src = this.item.goods_img;
      } else {
        e.target.src = require("@/assets/images/newsNoimg.png");
      }
    },
    day(str) {
      return this.$moment(str).format("YYYY.MM.DD");
    },
    chkGoodsStatus(goodsSeq) {
      const goodsStaus = this.item.compare_goods_status.split("|");
      let status = "";
      for (let key in goodsStaus) {
        if (parseInt(goodsSeq) === parseInt(goodsStaus[key].split(":")[0])) {
          status = goodsStaus[key].split(":")[1];
          break;
        }
      }
      return status;
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.isOpen
                ? `${this.nowPage.replace(
                    process.env.VUE_APP_API_URL,
                    ""
                  )}&seq=${this.item.seq}`
                : this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
    async likeCheck(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }

      await this.$store.dispatch("wish/updateWish", params);
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateWish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
      // if (params.type === "review") {
      //   this.$router.push(`/wish/review?menu=${this.goodsMenu}`);
      // }
    },
    async likeShop(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }
      //reviewer_seq 파라메터는 상황에 따라 리뷰어, 리뷰, 가게 번호가 됨.
      await this.$store.dispatch("wish/updateWishShop", {
        reviewer_seq: params.review_seq,
        wish_yn: params.wish_yn,
      });
      //reviewer, seq 파라메터는 상황에 따라 리뷰어, 리뷰, 가게 번호가 됨.
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateWish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
    },
    goDetailList() {
      this.$emit("goDetailList", this.item.goods_seq);
    },
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    openDetail() {
      const detail = document.querySelector(`#item_detail_${this.item.seq}`);
      const arrow = document.querySelector(`#arrow_${this.item.seq}`);
      const box2 = document.querySelectorAll(".box_2.detail");
      const video = document.querySelectorAll(
        `#item_detail_${this.item.seq} .ql-video`
      );
      if ([...video].length > 0) {
        const height = document.querySelector("body").offsetWidth * 0.5625;
        [...video].map((v) => {
          v.style.width = "100%";
          v.style.height = `${height - 10}px`;
          const src = `${v.src}&enablejsapi=1`;
          const index = v.src
            .split("&")
            .findIndex((s) => s === "enablejsapi=1");
          if (index === -1) {
            v.src = src;
          }
        });
      }
      this.resetDetail(); //초기화
      [...box2].map((box) => {
        if (box !== detail) box.style.display = "none";
      });
      if (detail.style.display === "none") {
        detail.style.display = "block";
        arrow.textContent = "∧";
        this.$store.dispatch("review/setViewHit", {
          review_seq: this.item.seq,
        });
        this.isOpen = true;
        this.$emit("setSeq", this.item.seq);
      } else {
        if ([...video].length > 0) {
          [...video].map((v) => {
            v.contentWindow.postMessage(
              '{"event":"command","func":"' + "stopVideo" + '","args":""}',
              "*"
            );
          });
        }
        detail.style.display = "none";
        arrow.textContent = "∨";
        this.isOpen = false;
        this.$emit("setSeq", "0");
      }
    },
    resetDetail() {
      // 상세 페이지가 열릴 떄 마다 초기화
      this.item.review_score = this.orgReviewScore;
      this.item.score = this.orgScore;
      this.reviewTmpScore = 0;
      this.tmPscore = 0;
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      const video = document.querySelectorAll(".ql-video");
      const detailArrow = document.querySelectorAll(".detail_arrow");
      const infoWord = document.querySelectorAll(".info_word");
      const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);

      [...infoWord].map((word) => {
        word.style.display = "none";
      });
      arrowInfo.textContent = "∨";

      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      [...detailArrow].map((deatil) => (deatil.textContent = "∨"));
      arrowReply.textContent = "∨";
      if ([...video].length > 0) {
        [...video].map((v) => {
          v.contentWindow.postMessage(
            '{"event":"command","func":"' + "stopVideo" + '","args":""}',
            "*"
          );
        });
      }
      if (parseInt(this.item.option_category1) === 2) {
        this.$refs["review_score"].clearTopTab();
      }
    },
    openInfo() {
      const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);
      const arrowInfoWord = document.querySelector(
        `#arrow_info_word_${this.item.seq}`
      );

      const infoWord = document.querySelectorAll(".info_word");
      [...infoWord].map((word) => {
        if (word !== arrowInfoWord) word.style.display = "none";
      });
      if (arrowInfoWord.style.display === "none") {
        arrowInfoWord.style.display = "block";
        arrowInfo.textContent = "∧";
      } else {
        arrowInfoWord.style.display = "none";
        arrowInfo.textContent = "∨";
      }
    },
    openDeclaration() {
      if (!this.needsLogin()) return false;

      const allPop = document.querySelectorAll(".declaration_pop");
      const pop = document.querySelector(`#declaration_${this.item.seq}`);

      [...allPop].map((ap) => {
        if (ap !== pop) {
          ap.style.display = "none";
        }
      });
      if (pop.style.display === "none") {
        pop.style.display = "block";
      } else {
        pop.style.display = "none";
      }
    },
    compareGoods(item) {
      let data = [];
      let goods_name = item.compare_goods_name.split("|");
      let score = item.compare_score.split(",");
      let seq = item.compare_goods_seq.split(",");

      goods_name.forEach((name, index) => {
        data.push({
          goods_name: name,
          score: score[index],
          seq: seq[index],
        });
      });
      return data;
    },
    openPopSns() {
      this.$refs[`sns_share_${this.item.seq}`].openPopSns(this.item.seq);
    },
    openReply() {
      const replyEl = document.querySelector(`#reply_view_${this.item.seq}`);
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      const reRePlyList = document.querySelectorAll(".re_reply_box");
      [...reRePlyList].map((list) => {
        list.style.display = "none";
      });
      [...document.querySelectorAll(".replyArrow")].map(
        (re) => (re.textContent = "∨")
      );
      const replyList = document.querySelectorAll(".reply_list_box");
      [...replyList].map((reply) => {
        if (reply !== replyEl) {
          reply.style.display = "none";
        }
      });

      if (replyEl.style.display === "none") {
        replyEl.style.display = "block";
        arrowReply.textContent = "∧";

        if (
          this.$store.state.member.isLogin &&
          this.item.review_score !== null &&
          this.item.score !== null
        ) {
          this.$refs[`reply_view_${this.item.seq}`].isCheckAuth = true;
        }
        this.getReplyList(true);
      } else {
        replyEl.style.display = "none";
        arrowReply.textContent = "∨";
      }
    },
    setSort(sort) {
      this.sort = sort;
      this.getReplyList(true);
    },
    async getReplyList(reset = false) {
      if (reset) {
        this.replyPage = 0;
      }
      await this.$store.dispatch("review/getReplyList", {
        seq: this.item.seq,
        page: this.replyPage,
        sort: this.sort,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.replyPage++;
    },
    reviewScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    evaluationScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    setReviewScore(score) {
      if (!this.needsLogin()) return false;

      if (this.item.review_score !== null) {
        if (parseInt(this.reviewTmpScore) > 0 && parseInt(this.tmPscore) <= 0) {
          this.$toast.default(
            "이미 리뷰 만족도를 부여하셨습니다. 제품 평점을 부여해주세요. 그래야 포인트를 드립니다.",
            { duration: 3000 }
          );
          return false;
        }

        if (parseInt(this.item.review_score) > 0) {
          this.$toast.default("이미 리뷰 만족도를 평가 하셨습니다.");
          return false;
        } else {
          this.$confirm(`리뷰 만족도 ${score}점을 부여하시겠습니까?`)
            .then(async () => {
              this.reviewTmpScore = score;
              this.item.review_score = score;
              if (this.tmPscore <= 0) {
                this.$toast.default(
                  "제품 평점을 부여해주세요. 그래야 포인트를 드립니다.",
                  { duration: 3000 }
                );
                return false;
              } else {
                await this.setMakeReviewGoodsScore();
              }
            })
            .catch(() => {
              return false;
            });
        }
      } else {
        this.$toast.default("해당 리뷰를 통해 최근 구매한 이력이 없습니다.");
        return false;
      }
    },
    setGoodsScore(score, goodsSeq) {
      if (!this.needsLogin()) return false;

      if (this.item.score !== null) {
        if (parseInt(this.item.main_goods_seq) !== parseInt(goodsSeq)) {
          this.$toast.default("평점을 부여할 수 없는 상품 입니다.");
          return false;
        }

        if (parseInt(this.tmPscore) > 0 && parseInt(this.reviewTmpScore) <= 0) {
          this.$toast.default(
            "이미 제품 평점을 부여하셨습니다. 리뷰 만족도를 부여해주세요. 그래야 포인트를 드립니다.",
            { duration: 3000 }
          );
          return false;
        }

        if (parseInt(this.item.score) > 0) {
          this.$toast.default("이미 제품 평점을 부여하셨습니다.");
          return false;
        } else {
          this.$confirm(`제품 평점 ${score}점을 부여하시겠습니까?`)
            .then(async () => {
              this.tmPscore = score;
              this.item.score = score;
              if (this.reviewTmpScore <= 0) {
                this.$toast.default(
                  "리뷰 만족도를 부여해주세요. 그래야 포인트를 드립니다.",
                  { duration: 3000 }
                );
                return false;
              } else {
                await this.setMakeReviewGoodsScore();
              }
            })
            .catch(() => {
              return false;
            });
        }
      } else {
        this.$toast.default("해당 리뷰를 통해 최근 구매한 이력이 없습니다.");
        return false;
      }
    },
    updateScores(reviewScore, score) {
      this.orgReviewScore = reviewScore;
      this.orgScore = score;
    },
    async setMakeReviewGoodsScore() {
      await this.$store.dispatch("review/setMakeReviewGoodsScore", {
        review_score: this.item.review_score,
        score: this.item.score,
        goods_seq: this.item.main_goods_seq,
        review_seq: this.item.seq,
        order_seq: this.item.order_seq,
      });
      if (this.result) {
        this.orgReviewScore = this.item.review_score;
        this.orgScore = this.item.score;
      }
      this.$toast.default(this.msg);
    },
    deleteReview() {
      const saleCnt = parseInt(this.item.sale_cnt);
      let msg = "해당 리뷰를 삭제하시겠습니까?";
      if (saleCnt > 0) {
        msg =
          "이미 [구매하기]가 이루어진 리뷰입니다. [삭제하기]를 누르면, 앞으로 6개월 동안 이 리뷰를 통한 [구매하기]가 불가능해집니다. 이 리뷰는 6개월 후 자동 삭제됩니다. 계속하시겠습니까? ";
      }
      this.$confirm(msg)
        .then(async () => {
          await this.$store.dispatch("review/deleteReview", {
            seq: this.item.seq,
            saleCnt,
          });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    goOrder(category, goods_seq) {
      // if (!this.$store.state.member.isLogin) {
      //   this.$confirm("로그인이 필요합니다.")
      //     .then(() => {
      //       this.$store.dispatch("common/setReferer", {
      //         path: `${this.nowPage.replace(
      //           process.env.VUE_APP_API_URL,
      //           ""
      //         )}&seq=${this.item.seq}`,
      //       });
      //       this.$router.push("/member/login");
      //       return false;
      //     })
      //     .catch(() => {
      //       return false;
      //     });
      //   return false;
      // }

      this.$router.push(
        `/market_goods_detail/${category}/${goods_seq}?review_seq=${this.item.seq}`
      );
    },
    goCompareItem(index) {
      // if (!this.$store.state.member.isLogin) {
      //   this.$confirm("로그인이 필요합니다.")
      //     .then(() => {
      //       this.$store.dispatch("common/setReferer", {
      //         path: `${this.nowPage.replace(
      //           process.env.VUE_APP_API_URL,
      //           ""
      //         )}&seq=${this.item.seq}`,
      //       });
      //       this.$router.push("/member/login");
      //       return false;
      //     })
      //     .catch(() => {
      //       return false;
      //     });
      //   return false;
      // }

      if (index <= 0) {
        this.$router.push(
          `/market_goods_detail/${this.comCategory1}/${this.compareItem1}?review_seq=${this.item.seq}`
        );
      } else {
        this.$router.push(
          `/market_goods_detail/${this.comCategory2}/${this.compareItem2}?review_seq=${this.item.seq}`
        );
      }
    },
    modifyReview(type) {
      const query = `?${this.paramsQuery}&seq=${this.item.seq}&mode=modify`;
      // console.log(query);

      if (type === "normal") {
        this.$router.push(
          `/review/review_normal_goods_write/${this.category}/${this.item.seq}${query}`
        );
      } else if (type === "pro") {
        this.$router.push(`/review/review_pro_goods_write${query}`);
      } else if (type === "seller") {
        this.$router.push(
          `/review/review_seller_goods_write/${this.category}/${this.item.seq}${query}`
        );
      } else {
        this.$toast.default("유효하지 않은 유형입니다.");
        return false;
      }
    },
  },
  computed: {
    ...mapState("mypage", ["myInfo", "channel"]),
    ...mapState("review", ["replyListArray", "msg", "result", "limit"]),
    compareItem1() {
      if (this.item.compare_goods_seq) {
        if (this.item.compare_goods_seq.split(",")[0]) {
          return this.item.compare_goods_seq.split(",")[0];
        }
      }
      return 0;
    },
    compareItem2() {
      if (this.item.compare_goods_seq) {
        if (this.item.compare_goods_seq.split(",")[1]) {
          return this.item.compare_goods_seq.split(",")[1];
        }
      }
      return 0;
    },
    comCategory1() {
      if (this.item.compare_category) {
        if (this.item.compare_category.split(",")[0]) {
          return this.item.compare_category.split(",")[0];
        }
      }
      return 0;
    },
    comCategory2() {
      if (this.item.compare_category) {
        if (this.item.compare_category.split(",")[1]) {
          return this.item.compare_category.split(",")[1];
        }
      }
      return 0;
    },
    isReviewer() {
      if (
        this.$store.state.mypage.myInfo.is_reviewer === "Y" &&
        this.$store.state.mypage.channel.reviewer
      ) {
        return this.$store.state.mypage.myInfo.member_seq;
      }
      return 0;
    },
    isSeller() {
      if (
        this.$store.state.mypage.myInfo.is_seller === "Y" &&
        this.$store.state.mypage.channel.seller
      ) {
        return this.$store.state.mypage.myInfo.member_seq;
      }
      return 0;
    },
    pageUrl() {
      // console.log(this.nowPage);
      return this.nowPage;
    },
  },
  components: {
    ReviewSnsShare,
    ReviewReply,
    ReviewDeclaration,
    ReviewSetScore,
  },
};
</script>

<style lang="scss" scoped>
.box {
  .box_2 {
    .btn_1 {
      .no_sale {
        background: #d3d3d3 !important;
      }
    }
  }
}
.top_1,
.top_2 {
  a {
    span:first-child {
      margin-right: 5px;
    }
  }
}
</style>
